.App {
    text-align: center;
    background-color: inherit;
}

.entirePage {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background: whitesmoke;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
    overflow: hidden;
    overflow-y: scroll;

}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-Component {

}

#mainContent {
    margin: 0;
    padding-right: 0;
    padding-left: 0;
}

.App-header {
    background-color: #282c34;
    min-height: 90vh;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#toggle input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

#toggle label {
    cursor: pointer;
    text-indent: -9999px;
    width: 52px;
    height: 27px;
    background: grey;
    float: right;
    border-radius: 100px;
    position: relative;
}

#toggle label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

#toggle input:checked + label {
    background: var(--dark-background);
}

#toggle input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

#toggle label:active:after {
    width: 45px;
}

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
