#cancel		{
	color:red;
	font-size:18pt;
	text-align:center;
	}

#JointMeetingsHeader    {
    padding-bottom: 0;
}

#JoinSubHeader  {
    font-size: 12pt;
    padding-top: 0;
}

p	{
	font-size:12pt;
	text-align:left;
	margin: 0px 50px 0px 50px;
	}
	