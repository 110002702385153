.divisionsPage {
    margin-right: 2.5vw;
    margin-left: 2.5vw;
}

.divisionLink:hover {
    opacity: 0.8;
    -ms-transform: scale(0.95); /* IE 9 */
    -webkit-transform: scale(0.95); /* Safari 3-8 */
    transform: scale(0.95);
    cursor: pointer;
    transition: .5s ease;

}

.divisionLink {
    margin: auto;
}

@media only screen and (min-width: 765px) {
    .divisionsPage td {
        width: 20vw
    }

    .FLTable {
        width: 20vw
    }
}

.FLTable {
    width: 60vw
}

.divisionsPage td:hover {
    background-color: green;
    color: goldenrod;
    cursor: pointer;
}

.LAHotelInfo td:hover {
    color: black;
    background-color: white;
    cursor: auto;
}

.FLTable td:hover {
    color: black;
    background-color: white;
    cursor: auto;
}


.divisionsPage .selected {
    color: goldenrod;
    background-color: green;
}

.divisionsPage td h5 {
    font-size: 1rem;
}

.divisionImage {
    max-width: 30vw;
}

.LAPageHeader {
    font-size: 0.8rem;
}

.pageBodySection {
    margin-left: 4.5vw;
}

#FloridaPage #Scholarship:hover {

}

.FLDropDown {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #056236;
}



.dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #056236;
}

#Officers {
    color: black;
    font-weight: 500;
}

#FloridaDivisionMeetings {
    color: black;
    font-weight: 500;
}

#Officers1:hover {
    color: goldenrod !important;
}

#Officers1:hover + #Officers {
    color: goldenrod !important;
}

.FLDropDown {
    color: black;
}

@media only screen and (max-width: 765px) {
    .FLDropDown {
    }
}
