#homePageHeader h2 {
    padding: 15px;
    text-align: center;
}
.HomePage   {
    margin-right: 5.5vw;
    margin-left: 5.5vw;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: black;
    background-size: 100%, 100%;
    background-image: none;
}

.carousel-control-next-icon:after
{
    content: '>';
    font-size: 55px;
    color: darkgreen;
}

.carousel-control-prev-icon:after {
    content: '<';
    font-size: 55px;
    color: darkgreen;
}

#subHeadLine    {
    font-size: 10pt;
    text-align: center;
    color: goldenrod;
}

.carousel-caption {
    color: darkgreen;
    font-weight: bold;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.homeHeader {
    padding-top: 0;
}
.carousel-inner {
    align-content: center;
    text-align: center;
    max-height: 80vh;
    max-width: 100%;
    margin: auto;
}

@media only screen and (min-width: 765px) {
    .carousel-inner {
        max-width: 75%;
    }
}

#homePageCarousel {
    padding: 0;
}

#carouselExampleIndicators {
    padding-left: 0;
    padding-right: 0;
    max-width: 100vw;
}

#orgTitle {
    font-size: 1.3rem;
}

#aboutText  {
    text-align: justify;
    margin: auto;
    width: 90vw;
}

#exampleModalLong   {
    text-align: justify;
}

#learnMoreText  {
    font-weight: 500;
}
#learnMoreText:hover  {
    cursor: pointer;
    text-decoration: underline;
    color: #056236;
}

#homePageModalHeader    {
    text-align: center;
}
@media only screen and (max-width: 420px) {
    #homePageHeader h2 {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 765px) {
    #homePageHeader h2 {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 1000px) {
    #homePageHeader h2 {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 600px) {

    .homePageButtons {
        padding-top: 10px;
        text-align: center;
    }

    .homePageButton {
        display: inline;
        padding: 15px;
    }

    .btn-primary {
        color: black;
        border: 2px solid black;
        background-color: rgba(255, 255, 240, 0.8);
        width: 250px;
        height: 50px;

    }

}

@media only screen and (max-width: 599px) {

    .btn-primary {
        border: 2px solid black;
        color: black;
        background-color: rgba(255, 255, 240, 0.8);
        width: 250px;
        height: 50px;
        text-align: center;

    }

    .homePageButton {
        text-align: center;
        padding-top: 10px;
    }

    .homePageButtons {
        padding-top: 10px;
        text-align: center;
    }

    #eventButton {
        padding-top: 10px;
    }

}