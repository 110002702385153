#Journals {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

#JournalSubHeadLine {
    text-align: justify;
    color: goldenrod;
    font-size: 9.5pt;
}

#selectedJournal {
    margin: auto;
}

#Journals hr {
    margin: 0;
}

#onlineIssueSection {
    margin-left: 3.5vw;
}

#selectedJournal {
    padding: 5px;
    border: 2px solid black;
}

#journalNote {
    text-align: justify;
    font-size: 0.8rem;
}

.volume {
}

.volume:hover {
    cursor: pointer;
    opacity: .7;
}

.onlineIssues {
    margin-bottom: 3.5vh;
}

@media only screen and (min-width: 500px) {
    #volumeSection {
        max-width: 50vw;
    }
}