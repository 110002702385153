#officersPage   {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

@media only screen and (min-width: 650px) {
    #officersPage #executiveOfficersTable {
        margin: auto;
        max-width: 80vw;
    }


    #officersPage   {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

.officerName    {
    text-decoration: underline;
    font-size: 1rem;
}

.officerInfo    {
    font-weight: normal;
}

#officersPage td{
    font-weight: bold;
}

#positionHeader {
    text-align: center;
}

#InternationalMembers   {
    margin-bottom: 15px;
}

#officerTitle   {
    text-align: center;
}

