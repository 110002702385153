#honoraryMembersPage {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

hr {
    color: white;
}

@media only screen and (min-width: 992px) {
    #honoraryMembersPage {
        margin-left: 6.5vw;
        margin-right: 6.5vw;
    }

}

#honoraryMemberPageHeader {
    color: goldenrod;
    text-align: justify;
}

.honoraryHeader {
    text-align: center;
}

.divisionName {
    text-decoration: underline;
    text-align: center;
    color: black;
}

.col-4 {
    text-align: left;
    font-size: 1.1em;
}

#honoraryMembersPage .row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 8px;
}

.honoree {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #056236;
    width: 118px;
    border: 1px solid black;
    padding: 0;

}

#honoraryMembersPage hr {
    margin: 0;
}

#honoraryMemberPageHeader {
    font-size: 0.8rem;
}
