#toggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

#toggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 52px;
  height: 27px;
  background: grey;
  float: right;
  border-radius: 100px;
  position: relative;
}

.donationButton {
  text-align: right;
}

.donationButton a {
  color: black;
}

#toggle label:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#toggle input:checked + label {
  background: var(--dark-background);
}

#toggle input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

#toggle label:active:after {
  width: 45px;
}

@media only screen and (max-width: 765px) {
  .donateButton {
    width: 25vw;
    font-size: 0.8rem;
  }
}