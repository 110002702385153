.page-footer img {
    padding-left: 12px;
    padding-right: 12px;
    align-content: center;
    max-height: 60px;
    max-width: 60px;
    text-align: center;
    z-index: 8;
}

.footer {
    padding-top: 8px;
    border-top: 2px solid #110B11;
    background-color: whitesmoke;
    align-content: center;
    height: 100%;
}

@media only screen and (max-width: 767px) {
    .footer {
        text-align: center;
    }
}

.louisianaFooter {
    margin: auto;
    width: 100% !important;
}

#floridaEmail   {
    font-size: 0.8rem;
}

.louisianaFooter a:hover {
    opacity: 0.7;
}

.footerSectionTitle {
    color: black;
}

.floridaFooter a:hover {
    opacity: 0.7;
}

.floridaFooter {
    width: 100%;
    margin: auto;
}

.footerBody {
    margin-left: 12vw;;
    margin-right: 5vw;
}

.national_info a {
    color: inherit;
}

.copyright {
    color: inherit;
    font-size: 9pt;
    padding-top: 12px;
    text-align: center;
}

.footerContent {
    font-size: 10pt;
}

.footerElement {
    padding-bottom: 8px;
}

.footerDonate   {
    width: 45vw;
    font-size: 0.8rem;
}