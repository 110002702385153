#MeetingsPage {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

#MeetingsPage td {
    width: 25% !important;
}

#MeetingsPage .table {
    margin-left: auto;
    margin-right: auto;
}


@media only screen and (min-width: 992px) {
    #MeetingsPage .table {
        width: 75vw;
    }

}
