.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.Component-header {
    background-color: #282c34;
    min-height: 90vh;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-Component  {
    background-color: white;
    padding-bottom: 8px;
  }

#carouselExampleControls  {
    display: block;
    align-content: center;
    min-height: 80vh;
    min-width: 80vw;

}

#carouselExampleControls .item  {
    min-height: 80vh;
    min-width: 80vw;
}

#carouselExampleControls .active{
    min-height: 80vh;
    min-width: 80vw;
}

.App-header {
    background-color: #282c34;
    min-height: 90vh;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.PageHeader {
    text-align: center;
    padding: 15px;
}

@media only screen and (max-width: 420px) {
    .PageHeader {
        font-size: 1.1rem;
    }

}

