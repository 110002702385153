.navbar {
    text-align: center;
}

#mainHeader {
    background-color: whitesmoke;
    min-height: 10vh;
    border-bottom: 2px solid black;
}

/*.row {*/
/*    background-color: inherit;*/
/*    min-height: 10vh;*/
/*}*/

#centerLogo {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 10%;
    margin-left: -50px;
}

#centerLogo .navbar-brand {
    margin: 0;
}


@media only screen and (min-width: 451px) {
    #logo {
        height: 53px;
        width: 150px;
    }
}

@media only screen and (max-width: 451px) {
    #logo {
        height: 40px;
        width: 115px;
    }
}


#logo {
    display: inline-block;
    text-align: center;
}

#main-menu .nav-item {
    color: #056236;
}

#main-menu a:hover {
    background-color: rgba(0, 0, 0, 0.1);;
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out;
}

#main-menu .active {
    font-weight: bold;
}

.dropdown-item  {
    max-width: 85vw;
    font-size: 10pt;
}

.navbar-toggler {
    background-color: rgba(255, 255, 255, 0.4);;
}

@media only screen and (max-width: 991px) {
    #dropdown-basic-button1 {
        font-size: 13pt;
        letter-spacing: 5px;
    }

    #dropdown-basic-button {
        font-size: 13pt;
        letter-spacing: 5px;
    }

    .dropdown-item{
        font-size: 9pt;
    }
}



#dropdown-basic-button1 {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #056236;
}

#dropdown-basic-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #056236;
}

@media only screen and (min-width: 992px) {
    #toggler {
        position: absolute;
        top: 0;
        right: 15px;
        cursor: pointer;

    }
}

@media only screen and (max-width: 991px) {
    .nav-item {
        font-size: 1rem;
        padding-top: 0;
        letter-spacing: 5px;
        text-align: right;

    }

}

@media only screen and (min-width: 992px) {
    .nav-item {
        font-size: 15px;
    }

}